<template>
  <div>
    <div v-if="courseData" class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <h2 class="mr-3">{{courseData.name}}</h2>
        <a-tag :color="courseData.statusPublication === 'published' ? 'green' : 'orange'">{{courseData.statusPublication === 'published' ? 'TERPUBLIKASI': 'DRAF'}}</a-tag>
      </div>
      <div>
        <a-button @click.prevent="deleteCourse" type="danger">
          <delete-outlined></delete-outlined>
          Hapus Course</a-button>
      </div>
    </div>
    <a-card>
      <div>
        <a-row class="mt-4" :gutter="[32,0]">
          <a-col :md="24" :lg="showUpload ? 16 : 24">
            <div class="text-center">
              <a-radio-group class="mb-3" v-model:value="activeMenu" button-style="solid">
                <a-radio-button value="general">Informasi Umum</a-radio-button>
                <a-radio-button value="sectionLesson">Section dan Lesson</a-radio-button>
                <!-- <a-radio-button value="banner">Banner</a-radio-button> -->
                <!-- <a-radio-button value="discountedPrice">Harga Diskon</a-radio-button> -->
              </a-radio-group>
            </div>
            <template v-if="activeMenu === 'general'">
              <general-menu :course="courseData" @fetchCourse="fetchCourse" :vendors="vendors" :categories="categories">
              </general-menu>
            </template>
            <template v-else-if="activeMenu === 'sectionLesson'">
              <section-lesson-menu :course="courseData" @fetchCourse="fetchCourse">
              </section-lesson-menu>
            </template>
            <template v-else-if="activeMenu === 'banner'">
              <banner-menu :course="courseData" :banner="bannerData" @fetchCourse="fetchCourse" @fetchBanner="fetchBanner"></banner-menu>
            </template>
            <template v-else-if="activeMenu === 'discountedPrice'">
              <discounted-price-menu :course="courseData" @fetchCourse="fetchCourse" :discounted-price-list="discountedPriceList" @fetchDiscountedCourse="fetchDiscountedCourse"></discounted-price-menu>
            </template>
          </a-col>
          <a-col :md="24" :lg="8" v-if="showUpload" class="d-flex justify-content-between">
            <a-divider class="h-100" type="vertical"></a-divider>
            <div class="w-100 th-upload-wrapper">
              <a-upload
                accept="image/jpeg,image/png"
                v-model:file-list="fileList"
                name="thumbnail"
                :action="`${backendUrl}api/course/upload-thumbnail/${idCourse}`"
                list-type="picture-card"
                :show-upload-list="false"
                :headers="{
                  authorization: 'authorization-text',
                }"
                :custom-request="customRequestUpload"
              >
                <div v-if="courseData.thumbnailSmall" class="w-100 text-center">
                  <img width="300" height="100" :src="`${backendUrl}${courseData.thumbnailSmall}`" alt="course" />
                </div>
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">Upload Thumbnail</div>
                </div>
              </a-upload>
              <div v-if="courseData.thumbnailSmall" class="text-center">
                <a-button @click.prevent="deleteBannerCourse" class="text-danger" type="link">
                  <delete-outlined></delete-outlined>
                  Hapus Banner Course</a-button>
              </div>
              <div>
                Keterangan:
                <ul>
                  <li>Thumbnail harus berformat JPG/PNG</li>
                  <li>Thumbnail harus memiliki rasio gambar sebesar 3:1</li>
                  <li>Thumbnail minimal berukuran 750x250</li>
                </ul>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, ref, createVNode, computed } from 'vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { PlusOutlined, LoadingOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message, Modal, notification } from 'ant-design-vue';
import apiClient from '@/services/axios'

const GeneralMenu = defineAsyncComponent(() => import('@/components/Course/General'))
const SectionLessonMenu = defineAsyncComponent(() => import('@/components/Course/SectionLesson'))
const BannerMenu = defineAsyncComponent(() => import('@/components/Course/Banner'))
const DiscountedPriceMenu = defineAsyncComponent(() => import('@/components/Course/DiscountedPrice'))

function getBase64(img) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result));
    reader.addEventListener('error', (err) => reject(err));
    reader.readAsDataURL(img);
  })
}
export default {
  components: { GeneralMenu, SectionLessonMenu, PlusOutlined, LoadingOutlined, BannerMenu, DiscountedPriceMenu, DeleteOutlined },
  setup() {
    let store = useStore()
    let courseData = ref({})
    let route = useRoute()
    const router = useRouter()
    const fileList = ref([])
    const activeMenu = ref('general')
    const loading = ref(false);
    const imageUrl = ref('');
    const idCourse = route.params.id
    const bannerData = ref({})

    const handleChange = info => {
      if (info.file.status === 'uploading') {
        loading.value = true;
        return;
      }

      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, base64Url => {
          imageUrl.value = base64Url;
          loading.value = false;
        });
        message.success('Upload berhasil.');
        fetchCourse()
      }

      if (info.file.status === 'error') {
        loading.value = false;
        message.error('upload error');
      }
    }

    const beforeUpload = async file => {
      try {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        imageUrl.value = await getBase64(file)
        if (!isJpgOrPng) {
          message.error('Anda hanya dapat mengunggah file JPG/PNG');
        }


        const loadImage = (url) => new Promise((resolve, reject) => {
          const img = new Image()
          img.addEventListener('load', () => resolve(img));
          img.addEventListener('error', (err) => reject(err));
          img.src = url
          // console.log(img)
        })

        const img = await loadImage(imageUrl.value)
        const dimension = {
          width: img.width,
          height: img.height,
        }


        const isRatioFit = Math.round(dimension.width / dimension.height) == 3
        if (!isRatioFit) {
          message.error('Anda hanya dapat mengunggah file dengan rasio gambar 3:1');
        }

        const isWidthFit = dimension.width >= 750 || dimension.height >= 250
        if (!isWidthFit) {
          message.error('Anda hanya dapat mengunggah file dengan ukuran minimal 750x250');
        }

        // const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isLt2M) {
        //   message.error('Image must smaller than 2MB!');
        // }

        // return isJpgOrPng && isLt2M;
        console.log(isJpgOrPng && isWidthFit && isRatioFit, 'masuk sini')
        return isJpgOrPng && isWidthFit && isRatioFit;
      } catch(err) {
        console.log(err)
      }
    };

    onMounted(async () => {
      fetchCourse()
      fetchVendors()
      fetchCategories()
      fetchBanner()
      // fetchDiscountedCourse()
    })

    const discountedPriceList = ref([])
    const fetchDiscountedCourse = async () => {
      const rawData = await store.dispatch('discountedCourse/FETCH_DISCOUNTED_COURSE_BY_ID_COURSE', { idCourse })
      // console.log(rawData)
      discountedPriceList.value = rawData.map(discountData => {
        return {
          ...discountData,
          key: discountData._id,
        }
      })
    }

    const vendors = ref([])
    const categories = ref([])

    const fetchVendors = async () => {
      const rawData = await store.dispatch('vendor/FETCH_VENDOR')
      vendors.value = rawData.map(vendor => {
        return {
          name: vendor.firstName + (vendor.lastName ? ' ' + vendor.lastName : ''),
          ...vendor,
        }
      })
    }

    const fetchCategories = async () => {
      categories.value = await store.dispatch('category/FETCH_CATEGORY')
    }

    const fetchCourse = async () => {
      courseData.value = await store.dispatch('course/FETCH_COURSE_BY_ID', {
        id: route.params.id,
      })
    }

    const fetchBanner = async () => {
      const res = await store.dispatch('banner/FETCH_BANNER_BY_COURSE', {
        idCourse: route.params.id,
      })

      bannerData.value = res || {}
    }

    const customRequestUpload = async (data) => {
      try {
        const isPassed = await beforeUpload(data.file)
        console.log(isPassed, 'isPassed Uplaod')
        if (!isPassed)  {
          return ''
        } else {
          if (data.file.status !== 'uploading') {
          }
          const formData = new FormData()
          formData.append(data.filename, data.file)
          const { data: { data:res } } = await apiClient.post(data.action, formData, {
            ...data.headers,
          })

          message.success(`file ${data.file.name} berhasil diunggah.`);
        }
        fetchCourse()
        fetchBanner()
      } catch (err) {
        message.error(`file ${data.file.name} gagal diunggah.`);
        fetchCourse()
        fetchBanner()
        console.log(err)
      }
    }

    const deleteCourse = () => {
      Modal.confirm({
          title: `Apa anda yakin ingin menghapus course ${courseData.value.name}?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              await store.dispatch('course/DELETE_COURSE', { idCourse })
              router.push({ name: 'Course' })
              notification.success({
                message: `Berhasil Menghapus Course ${courseData.value.name}.`,
              })
            } catch (err) {
              notification.error({
                message: `Gagal Menghapus Course ${courseData.value.name}.`,
              })
              console.log(err)
            }
          },
          onCancel() {},
        })
    }

    const showUpload = computed(() => {
      return activeMenu.value === 'general' || activeMenu.value === 'sectionLesson' || (activeMenu.value === 'discountedPrice' && courseData.value.type === 'free')
    })

    const deleteBannerCourse = () => {
      Modal.confirm({
          title: `Apa anda yakin ingin menghapus banner ${courseData.value.name}?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              await store.dispatch('course/DELETE_COURSE_BANNER', { idCourse })
              fetchCourse()
              fetchBanner()
              notification.success({
                message: `Berhasil Menghapus Banner ${courseData.value.name}.`,
              })
            } catch (err) {
              fetchCourse()
              fetchBanner()
              notification.error({
                message: `Gagal Menghapus Banner ${courseData.value.name}.`,
              })
              console.log(err)
            }
          },
          onCancel() {},
        })
    }

    return {
      courseData,
      activeMenu,
      fileList,
      imageUrl,
      handleChange,
      beforeUpload,
      loading,
      fetchCourse,
      idCourse,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      vendors,
      categories,
      fetchBanner,
      bannerData,
      fetchDiscountedCourse,
      discountedPriceList,
      customRequestUpload,
      deleteCourse,
      showUpload,
      deleteBannerCourse,
    }
  },
}
</script>
<style lang="scss">
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}
</style>
